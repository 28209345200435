import * as React from "react"
import Layout from '../components/Layout'
// markup
const ServicesPage = () => {
  return (
    <Layout>
      <h1>Services</h1>
      <p>
        I am passionate about providing empathetic treatment that is client centered.  
        <br/>
        Based on your needs, I utilize the following therapeutic approaches: Cognitive Behavioral Therapy (CBT), Psychoeducational, Motivational Interviewing, Psychodynamic, and Rational Emotive Behavioral Therapy (REBT).   
        <br/>
        I offer individual and group therapy services. Individual sessions are typically 45-60 minutes in length and group sessions are 60 minutes in length.  
      </p>
      <br/>
      <h1>Fees and Insurance</h1>
      <p>
        I am currently accepting the following insurances: Cigna, Health First, Beacon, Emblem and Blue Cross Blue Sheild. 
        <br/>
        I also work with out of network benefits.
        <br/>
        Additionally, I have a reasonable self-pay rate that we can discuss if you are interested. 
      </p>

    </Layout>
  )
}

export default ServicesPage